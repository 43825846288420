import { Suspense } from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import { Auth0Provider } from "@auth0/auth0-react";
import "./shared/services/i18n";
import LoaderComponent from './components/app/Loader/Loader'

const container = document.getElementById('root');
const root = createRoot(container as any);

root.render(
  <Suspense fallback={<LoaderComponent />}>
    <Auth0Provider
      domain="dalmata.eu.auth0.com"
      clientId="VbRfZyPLhMwj564iPsy9ivBBhY5QvXUw"
      redirectUri={window.location.origin}
      audience='dalmata-website'
      scope="read:current_user"
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </Suspense>
);
