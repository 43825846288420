import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // path to your translation files
    },
    detection: options,
    supportedLngs: ["en", "fr", "it", "ar"],
    defaultNS: "translation", // ensure your translation file has the same namespace
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    initImmediate: true,
    react: {
      useSuspense: true,
    },
  });

export default i18next;
