import loadable from '@loadable/component';
import { Fragment, useEffect } from 'react';
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import './App.scss';

const TopFrame = loadable(() => import('./components/top-frame/top-frame'));
const Aboutus = loadable(() => import('./components/app/Aboutus/aboutus'));
const Game = loadable(() => import('./components/app/Game/game'));
const Team = loadable(() => import('./components/app/Team/team'));
const Wecare = loadable(() => import('./components/app/Wecare/wecare'));
const Journal = loadable(() => import('./components/app/Journal/journal'));
const GenericMenuComponent = loadable(() => import('./components/app/Menu/generic-menu.component'));
const DalmataRestaurantComponent = loadable(() => import('./components/app/Restaurants/Dalmata/dalmata.component'));
const GiorgioRestaurantComponent = loadable(() => import('./components/app/Restaurants/Giorgio/giorgio.component'));
const Faq = loadable(() => import('./components/app/Faq/faq'));
const AdminComponent = loadable(() => import('./components/app/Admin/admin.component'));
const BookComponent = loadable(() => import('./components/app/cucina-colorata-book/cucina-colorata-book'));
const Footer = loadable(() => import('./components/footer/footer'));
const Home = loadable(() => import('./components/app/Home/home'));

const DebugLayout = () => {

  return <Outlet />;
};

const App = () => {
  return (
    <Fragment>
      <div id="frame">
        <TopFrame></TopFrame>
        <Routes>
          <Route element={<DebugLayout />}>
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/restaurant/dalmata" element={<DalmataRestaurantComponent />} />
            <Route path="/restaurant/giorgio" element={<GiorgioRestaurantComponent />} />
            <Route path="/restaurant/romano" element={<DalmataRestaurantComponent />} />
            <Route path="/restaurant/:location/menu/:type" element={<GenericMenuComponent />} />
            <Route path="/restaurant/:location/menu/:type/:qr" element={<GenericMenuComponent />} />
            <Route path="/game" element={<Game />} />
            <Route path="/cucina-colorata-book" element={<BookComponent />} />
            <Route path="/team" element={<Team />} />
            <Route path="/wecare" element={<Wecare />} />
            <Route path="/journal" element={<Journal />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/admin/*" element={<AdminComponent />} />
            <Route index path="/" element={<Home />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
        <Footer></Footer>
      </div>

    </Fragment>
  );
}

function NoMatch() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
}

export default App;
